<template>
  <div class="termgenerator">
    <m-frame
      :routeList="menuList"
      alive-include="Home"
      deleteApi="/detailmarkettool-uninstall-removeApp.html"
      title="详细页营销工具"
    />
  </div>
</template>

<script>
import MFrame from '@/components/frame/Frame.vue';
export default {
  components: {
    'm-frame': MFrame,
  },
  setup() {
    const menuList = [
      {
        path: '',
        title: '操作指南',
      },
      {
        path: 'list',
        title: '基础设置',
      },
    ];

    return {
      menuList,
    };
  },
};
</script>

<style lang="scss"></style>
